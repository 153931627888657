<template>
  <v-col cols="12">
    <cards-container
      :class="`das-card ${updateMood ? 'update-mood' : ''}`"
      :title="data.title"
      :date="data.last_report_date"
      :redirectionLink="redirectionLink"
      reportedAt
      :isHighlighted="data.is_highlighted"
      :updateMood="updateMood"
      @edit="updateMood = true"
      @save="updateMood = false"
      @cancel="updateMood = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col cols="12" md="6">
            <bodyMap
              :title="$t('sore_joints')"
              :joints="data.last_report.sore_joints"
              :updateMood="updateMood"
            />
          </v-col>
          <v-col cols="12" md="6">
            <bodyMap
              :title="$t('swollen_joints')"
              :joints="data.last_report.swollen_joints"
              :updateMood="updateMood"
            />
          </v-col>
        </v-row>

        <!-- this section is temporary commented till it been integrated it with BE -->
        <!-- <v-row class="programs body mt-10">
          <v-col cols="12" md="6" class="pb-1 pb-md-3">
            <v-chip class="mb-2 pl-8 pr-8" color="#EBF8FD" label flat>
              <p class="mb-0">
                {{ $t("erythrocyte") }} {{ $t("sedimentation") }}
                {{ $t("rate") }} (mm / hour)
                <br />
                <strong v-if="!updateMood">118</strong>
                <v-text-field v-else class="mt-1"></v-text-field>
                <small class="text-uppercase"> of 200</small>
              </p>
            </v-chip>
            <v-chip class="pl-8 pr-8" color="#EBF8FD" label flat>
              <p class="mb-0">
                {{ $t("overall") }} {{ $t("assessment") }} {{ $t("of") }}
                {{ $t("the") }} {{ $t("patient's") }} {{ $t("health") }}
                {{ $t("status") }}
                <br />
                <strong v-if="!updateMood">72</strong>
                <v-text-field v-else class="mt-1"></v-text-field>
                <small class="text-upper"> of 100</small>
              </p>
            </v-chip>
          </v-col>
          <v-col cols="12" md="6" class="pt-1 pt-md-3">
            <v-chip class="pl-8 pr-8 h-100 large" color="#EBF8FD" label flat>
              <p class="mb-0">
                <span>{{ $t("punctuation") }}</span>
                <strong v-if="!updateMood">6.72</strong>
                <v-text-field v-else class="mt-1"></v-text-field>
                <br />
                <small>
                  {{ $t("critical") }} {{ $t("illness") }} {{ $t("activity") }}
                </small>
              </p>
            </v-chip>
          </v-col>
        </v-row> -->
      </template>
    </cards-container>
  </v-col>
</template>

<script>
import CardsContainer from "./CardsContainer.vue";
import BodyMap from "../PartialBodyMap.vue";

export default {
  name: "SummaryCardBodyMapHands",

  data() {
    return {
      updateMood: false,
    };
  },

  props: {
    redirectionLink: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    CardsContainer,
    BodyMap,
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/cards/das-card.scss">
</style>