<template>
  <v-col cols="12">
    <cards-container
      class="summary-card-general"
      :title="data.title"
      :date="data.last_report_date"
      :redirectionLink="redirectionLink"
      :isHighlighted="data.is_highlighted"
    >
      <template v-slot:body>
        <v-row class="body">
          <v-col
            cols="12"
            class="pa-0 ma-0"
            v-for="(report, index) in data.last_report"
            :key="report.question_title+index"
          >
            <p class="question">{{ report.question_title }}</p>
            <p class="answer">
              <span>{{ report.option.option_title }}</span>
              <span v-if="report.option.answer_text"> ({{ report.option.answer_text }})</span>
            </p>
          </v-col>
        </v-row>
      </template>
    </cards-container>
  </v-col>
</template>

<script>
import CardsContainer from "./CardsContainer.vue";

export default {
  name: "SummaryCardGeneral",

  props: {
    redirectionLink: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    CardsContainer,
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/cards/summary-card-general.scss">
</style>