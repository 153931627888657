<template>
  <v-col cols="12" md="6">
    <cards-container
      class="progress-bars-card summary-card-only-score"
      :title="data.title"
      :date="data.last_report_date"
      :redirectionLink="redirectionLink"
      :isHighlighted="data.is_highlighted"
    >
      <template v-slot:body>
        <v-row class="body">
          <v-col cols="6" class="pt-0 pb-0 pl-0 mr-auto">
            <v-list-item-title class="mb-2">
              <span class="text-h5">{{ $t("score") }} </span>
              <span class="text-h4">{{ data.score.last_score }}</span>
              <small> {{ $t("of") }} {{ data.score.max_score }}</small>
            </v-list-item-title>
          </v-col>
        </v-row>
      </template> </cards-container
  ></v-col>
</template>

<script>
import CardsContainer from "./CardsContainer.vue";

export default {
  name: "SummaryCardOnlyScore",

  props: {
    redirectionLink: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    CardsContainer,
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/cards/summary-card-only-score.scss">
</style>