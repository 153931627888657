<template>
  <v-col cols="12">
    <cards-container
      class="progress-bars-card single-graph-plain-text-card"
      :title="data.title"
      :date="data.last_report_date"
      :redirectionLink="redirectionLink"
      :isHighlighted="data.is_highlighted"
    >
      <template v-slot:body>
        <v-row class="body">
          <v-col :cols="12" class="d-flex">
            <v-list-item-title class="mb-2 d-inline-flex justify-end">
              <div class="d-inline">
                <span class="text-h5">{{ $t("score") }} </span>
                <span class="text-h4">{{ data.score.last_score }}</span>
                <small> {{ $t("of") }} {{ data.score.max_score }}</small>
              </div>

              <div class="mb-1 mt-auto ml-6 item-subtitle">
                <span
                  :class="`incline-decline 
                  ${data.trend.value > 0 ? 'up' : 'down'}
                  `"
                >
                  <span
                    :class="`icomoon  
                    ${data.trend.value > 0 ? 'icon-up' : 'icon-down'}`"
                  ></span>
                  {{ Math.abs(data.trend.value) }}
                </span>
                {{ $t("since_last_report") }}
              </div>
            </v-list-item-title>

            <div class="progress-bars-container d-flex">
              <v-progress-linear
                v-for="i in 6"
                :key="i"
                :value="getProgressBarValue(i)"
                :color="
                  $store.getters['auth/user'] ? $store.getters['auth/user'].theme_config
                    .progress_bar_primary_color: ''
                "
                :style="`transform: rotate(270deg) translate(35px, ${
                  i * 30 - 5
                }px); margin-bottom:${i * 10};`"
                background-color="#DBDCDD"
              ></v-progress-linear>
            </div>
          </v-col>
        </v-row>
        <v-row
          class="pr-8 pl-8 pt-15"
          v-if="data.additional_question && data.additional_text"
        >
          <p class="question w-100">{{ data.additional_question }}</p>
          <p class="answer w-100 mb-0">
            <span>{{ data.additional_text }}</span>
          </p>
        </v-row>
      </template>
    </cards-container>
  </v-col>
</template>

<script>
import CardsContainer from "./CardsContainer.vue";
import progressBarGraphMixin from "@/mixins/progressBarGraph.js";

export default {
  name: "SummaryCardSingleGraphPlainText",

  props: {
    redirectionLink: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    CardsContainer,
  },

  mixins: [progressBarGraphMixin],
};
</script>

<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/cards/summary-card-single-graph-plain-text.scss">
</style>