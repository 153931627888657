<template>
  <v-col cols="12">
    <cards-container
      :class="`das-card ${updateMood ? 'update-mood' : ''}`"
      :title="data.title"
      :date="data.last_report_date"
      :redirectionLink="redirectionLink"
      reportedAt
      :updateMood="updateMood"
      :isHighlighted="data.is_highlighted"
      @edit="updateMood = true"
      @save="updateMood = false"
      @cancel="updateMood = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col cols="12" md="6">
            <BodyMap
              :title="$t('swollen_joints')"
              :joints="data.last_report.swollen_joints"
              :updateMood="updateMood"
            />
          </v-col>
          <v-col cols="12" md="6">
            <BodyMap
              :title="$t('sore_joints')"
              :joints="data.last_report.sore_joints"
              :updateMood="updateMood"
            />
          </v-col>
        </v-row>
      </template>
    </cards-container>
  </v-col>
</template>

<script>
import CardsContainer from "./CardsContainer.vue";
import BodyMap from "../BodyMap.vue";

export default {
  name: "SummaryCardBodyMapHandsFeet",

  data() {
    return {
      updateMood: false,
    };
  },

  props: {
    redirectionLink: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    CardsContainer,
    BodyMap,
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/cards/das-card.scss">
</style>