<template>
  <v-row
    class="patient-reported-outcome mt-1"
    v-if="formatedQuestionnaires.length > 0"
  >
    <v-col class="pt-0 pb-0" cols="12">
      <div class="h2 mb-5 pl-1 title">{{ $t(title) }}</div>
    </v-col>
    <v-col cols="12" class="p-0">
      <v-container class="padding-container pt-0 non-relapse-report">
        <v-row>
          <template v-for="questionnaire in formatedQuestionnaires">
            <component
              :is="questionnaire.key"
              :data="questionnaire"
              :key="questionnaire.title"
              :redirectionLink="
                $store.getters['auth/user'].theme_config.key == 'lifechamps'
                  ? `/${$i18n.locale}/members/${$route.params.id}/questionnaires/${questionnaire.id}`
                  : `/${$i18n.locale}/members/${$route.params.id}/detailed-view`
              "
            />
          </template>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import SummaryCardSingleGraph from "@/components/views/dashboard/members/id/partial/cards/SummaryCardSingleGraph.vue";
import SummaryCardBodyMapHands from "@/components/views/dashboard/members/id/partial/cards/SummaryCardBodyMapHands.vue";
import SummaryCardBodyMapHandsFeet from "@/components/views/dashboard/members/id/partial/cards/SummaryCardBodyMapHandsFeet.vue";
import SummaryCardBodyMapEsasR from "@/components/views/dashboard/members/id/partial/cards/SummaryCardBodyMapEsasR.vue";
import SummaryCardGeneral from "@/components/views/dashboard/members/id/partial/cards/SummaryCardGeneral.vue";
import SummaryCardSingleGraphPlainText from "@/components/views/dashboard/members/id/partial/cards/SummaryCardSingleGraphPlainText.vue";
import SummaryCardOnlyScore from "@/components/views/dashboard/members/id/partial/cards/SummaryCardOnlyScore.vue";

export default {
  name: "QuestionnaireCardsContainer",

  props: {
    questionnaires: {
      type: [Array, Object],
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      formatedQuestionnaires: [],
    };
  },

  components: {
    SummaryCardSingleGraph,
    SummaryCardBodyMapHands,
    SummaryCardBodyMapHandsFeet,
    SummaryCardBodyMapEsasR,
    SummaryCardSingleGraphPlainText,
    SummaryCardGeneral,
    SummaryCardOnlyScore,
  },

  beforeMount() {
    this.reformatQuestionnaireArray();
  },

  watch: {
    questionnaires() {
      this.reformatQuestionnaireArray();
    },
  },

  methods: {
    reformatQuestionnaireArray() {
      this.formatedQuestionnaires = this.questionnaires.map((questionnaire) => {
        if (questionnaire.key == "summary-card-only-score-body-map-hands") {
          questionnaire.key = "summary-card-body-map-hands";
        }
        if (
          questionnaire.key == "summary-card-only-score-body-map-hands-feet"
        ) {
          questionnaire.key = "summary-card-body-map-hands-feet";
        }

        return questionnaire;
      });
    },
  },
};
</script>
